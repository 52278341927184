.page-not-found {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: white;

  .material-icons {
    font-size: 20vw;
    color: $primary-color;
  }

  div {
    margin-top: 10px;
    font-size: 16px;
    font-weight: 600;
    color: $secondary-color;
  }
}

.item-container {
  display: flex;
  justify-content: space-between;
  padding: 3px;
  border-radius: 10px;
  background-color: white;
  overflow: hidden;
}

.item-image-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25%;
  border-radius: 10px;
}

.item-image {
  position: absolute;
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  object-fit: cover;
  background-repeat: no-repeat;
  border-radius: 8px 0 0 8px;
}

.item-detail {
  width: 60%;
  padding: 10px 10px;
  font-weight: 600;
  color: #707070;
}

.item-quantity-container {
  flex-direction: row;
  margin: auto;
  align-items: center;
  justify-content: center;
  width: 25%;
  font-size: 18px;
  font-weight: 600;
  color: white;
  line-height: 1;
  border-radius: 0 8px 8px 0;

  > * {
    margin: 1px 0;
  }

  .item-add-container {
    display: flex;
    border: 1px solid #707070;
    height: 40px;
    width: 100%;
    border-radius: 6px;
    margin-right: 5px;
    justify-content: space-between;
    align-items: center;
    .material-icons-round {
      background: #f4f4f4;
      color: #000;
      height: 100%;
      padding-top: 10px;
      font-size: 18px;
      font-weight: 400;
      text-align: center;
      cursor: pointer;
      width: 25px;
    }
    .rm-border {
      border-radius: 5px 0 0 5px;
    }
    .add-border{
      border-radius: 0 5px 5px 0;
    }

    .item-quantity {
      color: #000;
      padding: 7px;
    }
  }
}

.subcategory-item-container {
  width: 100%;
  font-size: 12px;
  font-weight: 600;
  color: $secondary-color;

  td {
    vertical-align: middle;
  }

  .star {
    color: $info-color;
    cursor: pointer;
  }
}

.subcategory-item-name {
  width: 70%;
  font-size: 14px;
}

.subcategory-quantity-container {
  display: flex;
  align-items: center;
  width: 65px;
  font-weight: 700;
  background-color: $background-color;
  border-radius: 6px;
  padding: 2px 7px;

  > * {
    margin: 0 3px;
    font-size: 14px;
  }

  span {
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
  }
}

.item-price {
  display: flex;
  align-items: center;
  span {
    margin-left: 5px;
    font-weight: 300;
    color: $info-color;
    cursor: pointer;
  }
}

.subcategory-item-price {
  white-space: nowrap;
}

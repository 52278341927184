.top-menu-search-container {
  position: fixed;
  right: 0;
  left: 0;
  width: 100%;
  background-color: $background-color;
}

.top-menu-container {
  display: flex;
  margin-bottom: 20px;
}

.button.top-left-favorite-button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 35px;
  min-width: 35px;
  padding: 0;
  background-color: $info-color;
  border: 1px solid $secondary-color;
  border-radius: unset;
  z-index: 2;
  cursor: pointer;

  .material-icons {
    font-size: 28px;
    color: white;
  }
}

.button.top-left-favorite-button.favourite-button-active {
  background-color: $primary-color;
}

.tab-content-container {
  height: 100vh;
  padding: 70px 0 40px 0;
  background-color: $background-color;
}

.tab-content-container-wp {
  height: calc(100vh - 60px);
  padding: 70px 0 40px 0;
  background-color: $background-color;
}

.tab-content {
  height: calc(100vh - 118px);
  padding: 0 20px 85px 20px;
  overflow-y: auto;

  .item-container:not(:last-child) {
    margin-bottom: 7px;
  }
}

.tab-content-wp {
  height: calc(100vh - 178px);
  padding: 0 20px 85px 20px;
  overflow-y: auto;

  .item-container:not(:last-child) {
    margin-bottom: 7px;
  }
}

.tab-content-without-search {
  margin-top: 0px;
}

.search-input {
  margin: 0 20px 20px;

  .suffix {
    font-size: 18px;
    color: $placeholder-color;
  }
}

.items-container {
  border-radius: 10px;
}

.checkout-button {
  position: fixed;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  width: 80%;
  padding: 15px;
  font-size: 16px;
  text-transform: uppercase;
  background-color: $success-color;
  box-shadow: 0px -10px 20px $success-variant-color;
}

.no-record-found {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 20px;
  font-size: 14px;
  font-weight: 400;
  color: $secondary-color;
}

.confirm-button {
  font-size: 14px;
  padding: 7px;
  width: 70%;
  background-color: $info-color;
}

.confirm-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.confirm-order-table-container {
  max-height: calc(80vh - 295px);
  width: 100%;
  background-color: white;
  overflow-y: auto;
}

.confirm-order-table {
  width: 100%;
  font-size: 14px;
  color: $secondary-color;
  border-collapse: separate;
  border-spacing: 0;

  th {
    position: sticky;
    top: 0;
    padding: 5px;
    background-color: white;
    border: 1px solid $border-color;
    z-index: 20;
  }

  td {
    background-color: white;
    border: 1px solid $border-color;
    padding: 2px 5px;
  }
}

.sub-total {
  width: 100%;
  margin-top: 5px;
  font-size: 18px;
  font-weight: 600;
  color: $secondary-color;
  text-align: end;
  margin-top: 30px;
}

.vat-details {
  width: 100%;
  margin-top: 5px;
  font-size: 18px;
  font-weight: 600;
  color: $secondary-color;
  text-align: end;
}

.grand-total {
  width: 100%;
  margin-top: 5px;
  font-size: 20px;
  font-weight: 600;
  color: $secondary-color;
  text-align: end;
}

.remarks {
  width: 100%;
  margin-top: 20px;
  font: $subtitle 400;
  color: $field-color !important;
  background-color: #ffffff;
}

.no-favorite-item,
.no-specials-item {
  height: 40vh;
}

.delete-item-icon {
  font-size: 20px;
  color: $primary-color;
  cursor: pointer;
}

.container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent linear-gradient(90deg, #56ccf2 0%, #2f80ed 100%) 0% 0% no-repeat padding-box;
}

.center-container {
  height: 600.79px;
  width: 330px;
  text-align: center;
}

.text-content {
  align-items: center;
  margin-top: 30px;
  .title {
    font-size: 40px;
    font-weight: 600;
    color: #fff;
    margin-top: 10px;
  }
  .description {
    color: #fff;
    font-weight: 600;
    margin-left: 50px;
    margin-right: 50px;
    padding: 20px;
  }
}

.form-control {
  padding: 10px;
  width: 100%;
  border-radius: 5px;
  height: 49px;
  border: 1px solid #8db9f4;
}

.form-input {
  display: flex;
}
.mt-3 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.form-btn {
  width: 100%;
  height: 49px;
  background: #2f80ed;
  border: 2px solid #ffffff;
  border-radius: 8px;
  color: #fff;
  font-weight: 600;
  font-size: 18px;
}

.form-btn:focus {
  outline: 0;
}

.radio-group {
  display: flex;
  align-items: center;
  .radio-text {
    color: #fff;
    font-weight: 600;
    font-size: 15px;
  }
}

.radio {
  margin: 0.5rem;
  input[type='radio'] {
    position: absolute;
    opacity: 0;
    + .radio-label {
      &:before {
        content: '';
        background: #f4f4f4;
        border-radius: 100%;
        border: 1px solid darken(#f4f4f4, 25%);
        display: inline-block;
        width: 1.2em;
        height: 1.2em;
        position: relative;
        vertical-align: top;
        margin-right: 2px;
        cursor: pointer;
        text-align: center;
        transition: all 250ms ease;
      }
    }
    &:checked {
      + .radio-label {
        &:before {
          background-color: #3197ee;
          box-shadow: inset 0 0 0 4px #f4f4f4;
        }
      }
    }
    &:focus {
      + .radio-label {
        &:before {
          outline: none;
          border-color: #3197ee;
        }
      }
    }
    + .radio-label {
      color: #fff;
      font-weight: 600;
      &:empty {
        &:before {
          margin-right: 0;
        }
      }
    }
  }
}

.header-container {
  margin-left: 5%;
  margin-right: 5%;
  display: flex;
  height: 60px;
  align-items: center;
}

.logo {
  height: 43px;
  width: 43px;
  border-radius: 30px;
  background: transparent linear-gradient(90deg, #56ccf2 0%, #2f80ed 100%) 0% 0% no-repeat padding-box;
}

.title{
    margin-left: 20px;
    font-size: 24px;
    font-weight: 600;
}
